import FormsFirebase from "./page/formFirebase";
import Adminis from "./page/adminis";
import { AuthProvider } from "./context/AuthContext";
import { HashRouter, Route, Routes, Link } from "react-router-dom";
import Home from "./page/home";
import Maracaibo from "./page/maracaibo";
import Admingame from "./page/admingame";
import Caracas from "./page/caracas";
import Merida from "./page/merida";
import Saltoangel from "./page/saltoangel";

//const socket = io("/");

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cartones" element={<FormsFirebase />} />
            <Route path="/adminis" element={<Adminis />} />
            <Route path="/admingame" element={<Admingame />} />
            <Route path="/mara" element={<Maracaibo />} />
            <Route path="/cara" element={<Caracas />} />
            <Route path="/meri" element={<Merida />} />
            <Route path="/salto" element={<Saltoangel />} />
          </Routes>
        </HashRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
