import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import useSound from "use-sound";
import { Button, Drawer, Radio, Space } from "antd";
import { Select } from "antd";
import { Link } from "react-router-dom";
import trofeow from "../img/trofeow.png";
import fotoreloj from "../img/reloj.gif";

import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import io from "socket.io-client";

//IMPORTAR SONIDOS
import popoff from "../sound/pop-up-off.mp3";
import popon from "../sound/pop-up-on.mp3";
import like from "../sound/rising-pops.mp3";
import bite from "../sound/bite.mp3";
import menuopen from "../sound/menu-open.mp3";
import bienvenido from "../sound/clases/bienvenido.mp3";

import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
  useFirestore,
  useUpdateDoc,
} from "react-firebase-hooks/firestore";
import {
  doc,
  setDoc,
  addDoc,
  updateDoc,
  getDocs,
  deleteDoc,
} from "@firebase/firestore";

import Confetti from "react-confetti";
import Carton from "../componentes/carton";
import Mostrarcarton from "../componentes/mostrarcarton";
import Cartonfull from "../componentes/cartonfull";
import Cartonfinal from "../componentes/cartonfinal";
var todoslosc = [];

const socket = io(process.env.REACT_APP_SOCKET);

var ganadors = [];
var cartonesg = [];

var cartonestodos = [];

const Admingame = () => {
  const { width, height } = "100%";

  const [activar, SetActivar] = useState(false);

  const [cartonesusers, setCartonesusers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cartos, setCartos] = useState([]);
  const [carton, setCarton] = useState([]);
  const [numcarton, setNumcarton] = useState([]);
  const [emailactual, setEmailactual] = useState([]);
  const [juego, setJuego] = useState([]);

  const [premio, setPremio] = useState(0);
  const [premion, setPremioN] = useState(0);
  const [premioinicial, setPremioinicial] = useState(0);

  //SONIDOS
  const [activar1] = useSound(popon);
  const [desactivar] = useSound(popoff);
  const [megusta] = useSound(like);
  const [comenta] = useSound(bite);
  const [principal] = useSound(menuopen);

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const mensaje = (value, tipo) => {
    msjsave(value, tipo);
  };

  // GENERAR CARTONES

  //

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };

  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState([]);

  const [tiempo, setTiempo] = useState(5);
  const [cierre, setCierre] = useState(false);
  const [cartonesusers2, setCartonesusers2] = useState([]);
  const [timefinal, setTimefinal] = useState(false);
  const [bolita, setBolita] = useState(0);
  const [sacada, setSacada] = useState([]);
  const [bolitas, setBolitas] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
  ]);
  /*const [figuras, setFiguras] = useState([
    "b1",
    "b2",
    "b3",
    "b4",
    "b5",
    "i1",
    "n1",
    "g1",
    "o1",
    "o2",
    "o3",
    "o4",
    "o5",
    "g5",
    "n5",
    "i5",
  ]);*/
  //const [namefigura, SetNamefigura] = useState("Cuadrado");
  const [figuras, setFiguras] = useState([]);
  const [namefigura, SetNamefigura] = useState("");
  const [ganadores, setGanadores] = useState([]);
  const [cartonesganadores, setCartonesganadores] = useState([]);

  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [activarbingo, setActivarbingo] = useState(false);
  const [iniciando, setIniciando] = useState(false);
  const [mostrarc, setMostrarc] = useState(false);
  const [sala, setSala] = useState("0");
  const [figuraurl, setFiguraurl] = useState("");

  const [min, setMin] = useState(0);
  const [seg, setSeg] = useState(0);

  const gettodosloscartones = () => {
    todoslosc = [];
    for (let i = 0; i < users.length; i++) {
      obtenermiscartones2(users[i].email, users[i].photo, users[i].nombre);
    }
    setCartonesusers2(todoslosc);
  };

  const obtenermiscartones2 = (correo, photo, nombre) => {
    const collectionRef = collection(db, `cartones/${correo}/miscartones`);
    getDocs(collectionRef).then((querySnapshot) => {
      const documentsArray = [];
      querySnapshot.forEach((doc) => {
        documentsArray.push({
          email: correo,
          photo: photo,
          nombre: nombre,
          id: doc.id,
          data: doc.data(),
        });
      });
      todoslosc.push(documentsArray);
    });
  };

  const getusers = async () => {
    const protemploCollection = collection(db, "cartones");
    const data = await getDocs(protemploCollection);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const obtenernombre = (correo, tipo) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email == correo) {
        let nombre = users[i].nombre;
        var PrimerN = "";
        for (let a = 0; a < nombre.length; a++) {
          if (nombre[a] === " ") {
            a = nombre.length;
          } else {
            PrimerN += nombre[a];
          }
        }
        if (tipo == "nombre") {
          return PrimerN;
        }
        if (tipo == "foto") {
          return users[i].photo;
        }
      }
    }
  };

  const todosloscartones = () => {
    todoslosc = [];
    for (let i = 0; i < documents.length; i++) {
      const collectionRef = collection(
        db,
        `cartones/${documents[i].id}/miscartones`
      );
      getDocs(collectionRef).then((querySnapshot) => {
        const arraydoc = [];
        querySnapshot.forEach((doc) => {
          arraydoc.push({ id: doc.id });
        });

        todoslosc.push({ id: documents[i].id, cartones: arraydoc });
      });
    }

    setCartonesusers(todoslosc);
  };

  const obtenerjuego = (sala, tipo) => {
    for (let i = 0; i < juego.length; i++) {
      if (juego[i].id === sala) {
        if (tipo === "premio") {
          setPremioinicial(juego[i].premio);
          setPremio(juego[i].premio);
        }
        if (tipo === "figura") {
          SetNamefigura(juego[i].figura);
        }
        if (tipo === "resultados") {
          setSacada(juego[i].resultados);
        }
        if (tipo === "quedaron") {
          setBolitas(juego[i].quedaron);
        }
        if (tipo === "estatus") {
          return juego[i].estatus;
        }
      }
    }
  };

  const getjuego = async () => {
    const protemploCollection = collection(db, "juegos");
    const data = await getDocs(protemploCollection);
    setJuego(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const vermiscartones = (correo) => {
    for (let i = 0; i < cartonesusers.length; i++) {
      if (cartonesusers[i].id == correo) {
        for (let a = 0; a < cartonesusers[i].cartones.length; a++) {
          //console.log(cartonesusers[i].cartones[a].id);

          const collectionRef = collection(
            db,
            `cartones/${correo}/miscartones`
          );
          getDocs(collectionRef).then((querySnapshot) => {
            const documentsArray = [];
            querySnapshot.forEach((doc) => {
              documentsArray.push({ id: doc.id, data: doc.data() });
            });
            setCartos(documentsArray);
            //console.log(documentsArray);
          });
        }
      }
    }
  };

  const updateinicio = (tipojuego, figuras, namefigura, quedaron) => {
    const misjuegos = doc(db, `juegos/${tipojuego}`);
    ganadors = [];
    cartonesg = [];
    updateDoc(misjuegos, {
      estatus: "Empezando",
      casillas: figuras,
      figura: namefigura,
      ganadores: [],
      cartonesganadores: [],
      quedaron: quedaron,
      figuraurl: figuraurl,
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });
  };

  const updatejugando = (tipojuego, figuras, namefigura, quedaron, sacadas) => {
    const misjuegos = doc(db, `juegos/${tipojuego}`);

    updateDoc(misjuegos, {
      estatus: "Jugando",
      casillas: figuras,
      figura: namefigura,
      ganadores: [],
      quedaron: quedaron,
      resultados: sacadas,
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });
  };

  const updatefinalizado = (
    tipojuego,
    figuras,
    namefigura,
    quedaron,
    sacadas
  ) => {
    const misjuegos = doc(db, `juegos/${tipojuego}`);

    updateDoc(misjuegos, {
      estatus: "Finalizado",
      casillas: figuras,
      figura: namefigura,
      ganadores: ganadors,
      cartonesganadores: cartonesg,
      quedaron: quedaron,
      resultados: sacadas,
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });
  };

  const enviarmensaje = (bolaactual, bolassacada, bolasquedan, estatusb) => {
    const newMessage = {
      body: [
        bolaactual,
        bolassacada,
        bolasquedan,
        figuras,
        estatusb,
        ganadors,
        sala,
      ],
      from: "Administrador",
    };

    socket.emit("message", newMessage.body);
  };

  const enviartiempo = (timeactual) => {
    const newMessage = {
      body: ["Empezando", timeactual, sala, namefigura, figuraurl],
      from: "Administrador",
    };

    socket.emit("message", newMessage.body);
  };

  const comenzar = () => {
    setMostrarc(true);
    setTiempo(70);
    setIniciando(true);
    setGanadores([]);
    setBolitas([
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
      58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
    ]);
    setSacada([]);
    setTimefinal(true);
    setActivarbingo(false);
    var bolitastodas = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
      58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
    ];
    updateinicio(sala, figuras, namefigura, bolitastodas);
  };

  const restablecer = () => {
    setMostrarc(true);
    setTiempo(30);
    setIniciando(true);
    setGanadores([]);
    obtenerjuego(sala, "quedaron");
    obtenerjuego(sala, "resultados");
    obtenerjuego(sala, "figura");

    setTimefinal(true);
    setActivarbingo(false);
  };

  const obtenerimg = () => {
    if (sala === "maracaibo") {
      return "https://lotoactivo.webcindario.com/bingo/maracaibo.png";
    }
    if (sala === "caracas") {
      return "https://lotoactivo.webcindario.com/bingo/caracas.png";
    }
    if (sala === "merida") {
      return "https://lotoactivo.webcindario.com/bingo/merida.png";
    }
    if (sala === "saltoangel") {
      return "https://lotoactivo.webcindario.com/bingo/saltoangel.png";
    }
    return "";
  };

  const obtenerfiguraurl = () => {
    if (namefigura === "4 Esquinas") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/4esquinas.jpg");
    }
    if (namefigura === "Rombo") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/rombo.jpg");
    }
    if (namefigura === "Carton lleno") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/cartonlleno.jpg");
    }
    if (namefigura === "Ahorcado") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/ahorcado.jpg");
    }
    if (namefigura === "Explosion") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/explosion.jpg");
    }
    if (namefigura === "10") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/10.jpg");
    }
    if (namefigura === "Ancla") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/ancla.jpg");
    }
    if (namefigura === "Avion") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/avion.jpg");
    }
    if (namefigura === "Champan") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/champan.jpg");
    }
    if (namefigura === "Cuadrado") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/cuadrado.jpg");
    }
    if (namefigura === "Diagonal") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/diagonal.jpg");
    }
    if (namefigura === "Escalera") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/escalera.jpg");
    }
    if (namefigura === "Flecha") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/flecha.jpg");
    }
    if (namefigura === "L") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/l.jpg");
    }
    if (namefigura === "Sputnik") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/sputnik.jpg");
    }
    if (namefigura === "T") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/t.jpg");
    }
    if (namefigura === "Tortuga") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/tortuga.jpg");
    }
    if (namefigura === "X") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/x.jpg");
    }
    if (namefigura === "Z") {
      setFiguraurl("https://lotoactivo.webcindario.com/bingo/z.jpg");
    }
  };

  const chequearjuego = () => {
    //console.log(obtenerjuego(sala, "estatus"));
    if (obtenerjuego(sala, "estatus") === "Jugando") {
      restablecer();
    }
  };

  const mostrarfigura = () => {
    if (namefigura === "4 Esquinas") {
      setFiguras(["b1", "b5", "o1", "o5"]);
    }
    if (namefigura === "Ahorcado") {
      setFiguras([
        "b1",
        "b2",
        "b3",
        "b4",
        "b5",
        "i1",
        "n1",
        "n5",
        "g1",
        "g2",
        "g3",
        "g4",
        "o3",
        "o5",
      ]);
    }
    if (namefigura === "Explosion") {
      setFiguras(["b1", "b5", "o1", "o5", "i3", "n2", "n4", "g3", "o1", "o5"]);
    }
    if (namefigura === "Rombo") {
      setFiguras([
        "b3",
        "i2",
        "i3",
        "i4",
        "n1",
        "n2",
        "n4",
        "n5",
        "g2",
        "g3",
        "g4",
        "o3",
      ]);
    }

    if (namefigura === "10") {
      setFiguras([
        "b1",
        "b2",
        "b3",
        "b4",
        "b5",
        "n1",
        "n2",
        "n4",
        "n5",
        "g1",
        "g5",
        "o1",
        "o2",
        "o3",
        "o4",
        "o5",
      ]);
    }

    if (namefigura === "Ancla") {
      setFiguras([
        "b1",
        "b4",
        "i1",
        "i5",
        "n1",
        "n2",
        "n4",
        "n5",
        "g1",
        "g5",
        "o1",
        "o4",
      ]);
    }

    if (namefigura === "Avion") {
      setFiguras(["b2", "b3", "b4", "i3", "g1", "g2", "g3", "g4", "g5", "o3"]);
    }

    if (namefigura === "Champan") {
      setFiguras([
        "b1",
        "i1",
        "i2",
        "i5",
        "n1",
        "n2",
        "n4",
        "n5",
        "g1",
        "g2",
        "g5",
        "o1",
      ]);
    }

    if (namefigura === "Cuadrado") {
      setFiguras([
        "b1",
        "b2",
        "b3",
        "b4",
        "b5",
        "i1",
        "i5",
        "n1",
        "n5",
        "g1",
        "g5",
        "o1",
        "o2",
        "o3",
        "o4",
        "o5",
      ]);
    }

    if (namefigura === "Diagonal") {
      setFiguras(["b1", "i2", "g4", "o5"]);
    }

    if (namefigura === "Escalera") {
      setFiguras([
        "i1",
        "i2",
        "i3",
        "i4",
        "i5",
        "n2",
        "n4",
        "g1",
        "g2",
        "g3",
        "g4",
        "g5",
      ]);
    }

    if (namefigura === "Flecha") {
      setFiguras(["b3", "i3", "n1", "n5", "g2", "g3", "g4", "o3"]);
    }

    if (namefigura === "L") {
      setFiguras(["b1", "b2", "b3", "b4", "b5", "i5", "n5", "g5", "o5"]);
    }

    if (namefigura === "Sputnik") {
      setFiguras([
        "b1",
        "b5",
        "i2",
        "i3",
        "i4",
        "n2",
        "n4",
        "g2",
        "g3",
        "g4",
        "o1",
        "o5",
      ]);
    }

    if (namefigura === "T") {
      setFiguras(["b1", "i1", "n1", "n2", "n4", "n5", "g1", "o1"]);
    }

    if (namefigura === "Tortuga") {
      setFiguras([
        "b2",
        "b5",
        "i2",
        "i3",
        "i4",
        "n1",
        "n2",
        "n4",
        "g2",
        "g3",
        "g4",
        "o2",
        "o5",
      ]);
    }

    if (namefigura === "X") {
      setFiguras(["b1", "b5", "i2", "i4", "g2", "g4", "o1", "o5"]);
    }

    if (namefigura === "Z") {
      setFiguras([
        "b1",
        "b5",
        "i1",
        "i4",
        "i5",
        "n1",
        "n5",
        "g1",
        "g2",
        "g5",
        "o1",
        "o5",
      ]);
    }

    if (namefigura === "Carton lleno") {
      setFiguras([
        "b1",
        "b2",
        "b3",
        "b4",
        "b5",
        "i1",
        "i2",
        "i3",
        "i4",
        "i5",
        "n1",
        "n2",
        "n4",
        "n5",
        "g1",
        "g2",
        "g3",
        "g4",
        "g5",
        "o1",
        "o2",
        "o3",
        "o4",
        "o5",
      ]);
    }
  };

  useEffect(() => {
    if (timefinal == true) {
      setTimeout(() => {
        if (tiempo > 0) {
          setTiempo(tiempo - 1);
          //console.log(tiempo);
          enviartiempo(tiempo);
        } else {
          setTimefinal(false);
          setIniciando(false);
          //console.log("SE ACTIVO");
          setActivarbingo(true);
        }
      }, 1000);
    }
  }, [timefinal, tiempo]);

  useEffect(() => {
    setMin(Math.floor(parseInt(tiempo) / 60));
    setSeg(parseInt(tiempo) % 60);
  }, [tiempo]);

  useEffect(() => {
    //console.log(activarbingo);

    if (activarbingo == true) {
      setTimeout(() => {
        if (ganadores.length > 0) {
          //console.log("FINALIZO");
          //console.log(ganadores);
          //console.log(cartonesganadores);
          setActivarbingo(false);
          setShowModal(true);
          setCierre(true);
          const bolitasmenos = sacada.slice(1);
          const bolastotal = bolitas;
          bolastotal.push(bolita);
          enviarmensaje(bolita, bolitasmenos, bolastotal, "Finalizado");
          updatefinalizado(sala, figuras, namefigura, bolitas, bolitasmenos);
        } else {
          //console.log(ganadores);
          if (bolitas.length > 0) {
            const numeroAleatorio = Math.floor(Math.random() * bolitas.length);
            let bolaactual = bolitas[numeroAleatorio];
            const bolassacada = [bolaactual, ...sacada];
            setSacada([bolaactual, ...sacada]);
            setBolita(bolitas[numeroAleatorio]);
            const bolasquedan = bolitas.filter(
              (numero) => numero !== bolitas[numeroAleatorio]
            );
            setBolitas(
              bolitas.filter((numero) => numero !== bolitas[numeroAleatorio])
            );

            enviarmensaje(bolaactual, bolassacada, bolasquedan, "Jugando");
            updatejugando(sala, figuras, namefigura, bolasquedan, bolassacada);

            principal();
          }
        }
      }, 7000);
    }
  }, [bolita, activarbingo]);

  useEffect(() => {
    socket.on("message", receiveMessage);

    return () => {
      socket.off("message", receiveMessage);
    };
  }, []);

  const receiveMessage = (message) => setMessages((state) => [message]);

  useEffect(() => {
    if (messages.length > 0) {
      if (messages[0].body[4] == "Ganador") {
        setGanadores(...ganadores, messages[0].body[5]);
        setCartonesganadores(messages[0].body[6]);
        ganadors = messages[0].body[5];
        cartonesg = messages[0].body[6];
        //console.log(ganadores);
        //console.log(cartonesganadores);
        //console.log(messages[0].body[5]);
      }
    }
    //console.log(messages);
  }, [messages]);

  useEffect(() => {
    if (ganadors.length == 0) {
    } else {
      let premionuevo = parseFloat(premioinicial) / parseInt(ganadors.length);
      //console.log(premionuevo);
      setPremioN(premionuevo.toFixed(2));
    }
  }, [ganadores]);

  useEffect(() => {
    //console.log(cartonesusers);
  }, [cartonesusers]);

  useEffect(() => {
    getusers();
  }, []);

  useEffect(() => {
    getjuego();
    gettodosloscartones();
  }, [users]);

  useEffect(() => {
    //console.log(documents);
    todosloscartones();
  }, [documents]);

  useEffect(() => {
    //console.log(juego);
  }, [juego]);

  useEffect(() => {
    //console.log(sala);
    obtenerjuego(sala, "premio");
    chequearjuego();
  }, [sala]);

  useEffect(() => {
    mostrarfigura();
    obtenerfiguraurl();
  }, [namefigura]);

  return (
    <>
      <div className="container w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
            <Link to="/">
              <img
                className="pt-2 mt-[-10px]"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            </Link>
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                  <img
                    onClick={handleLogout}
                    className="w-10 h-10  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />
                  <div className="font-medium text-white mt-[-10px]">
                    <div className="text-white text-right text-sm">
                      {displayName && <> {primerNombre} </>}
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>

      <br />

      {email == "enmarycarrasco@gmail.com" ? (
        <>
          {mostrarc == false ? (
            <div id="configt" className="flex">
              <div className=" bg-white w-full">
                <div className="m-4 text-blue-700 font-bold">
                  Seleccione la Sala
                </div>
                <div className="m-4">
                  <select
                    id="salas"
                    onChange={(e) => setSala(e.target.value)}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    <option value="0" selected>
                      Seleccione una Sala
                    </option>
                    {juego.map((val, key) => {
                      return <option value={val.id}>{val.id}</option>;
                    })}
                  </select>
                </div>
                <div className="m-4 text-blue-700 font-bold">
                  Seleccione la Figura
                </div>
                <div className="m-4">
                  <select
                    id="figuraajugar"
                    onChange={(e) => SetNamefigura(e.target.value)}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="0" selected>
                      Sin Seleccion
                    </option>
                    <option value="4 Esquinas">4 Esquinas</option>
                    <option value="Ahorcado">Ahorcado</option>
                    <option value="Explosion">Explosion</option>
                    <option value="Rombo">Rombo</option>
                    <option value="10">10</option>
                    <option value="Ancla">Ancla</option>
                    <option value="Avion">Avion</option>
                    <option value="Champan">Champan</option>
                    <option value="Cuadrado">Cuadrado</option>
                    <option value="Diagonal">Diagonal</option>
                    <option value="Escalera">Escalera</option>
                    <option value="Flecha">Flecha</option>
                    <option value="L">L</option>
                    <option value="Sputnik">Sputnik</option>
                    <option value="T">T</option>
                    <option value="Tortuga">Tortuga</option>
                    <option value="X">X</option>
                    <option value="Z">Z</option>
                    <option value="Carton lleno">Carton lleno</option>
                  </select>
                </div>
                <div className="m-4">
                  <button
                    type="button"
                    onClick={() => {
                      comenzar();
                    }}
                    class=" mt-2 w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
                  >
                    COMENZAR JUEGO
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <br />
          {iniciando == true ? (
            <>
              <div className="h-[100px] rounded-xl border-2 border-blue-900 m-4 flex place-content-center bg-[#e36c4c]">
                <img src={fotoreloj} />
                {min == 0 ? (
                  <span className="mt-[32px] text-2xl text-white  font-semibold">
                    {seg} Segundos
                  </span>
                ) : (
                  <span className="mt-[32px] text-2xl text-white  font-semibold">
                    {min}:{seg} Segundos
                  </span>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          <div>
            <div>
              {cierre == true ? (
                <div className="flex flex-wrap place-content-center m-4">
                  {Object.keys(cartonesusers2).map((key) =>
                    cartonesusers2[key]
                      .filter((item) => item.data.estatus !== "pendiente")
                      .map((item, index) => (
                        <Cartonfinal
                          key={`${key}${index}`}
                          id={item.id}
                          idcarton={cartonesganadores}
                          nombre={item.nombre}
                          photo={item.photo}
                          numeros={item.data}
                          sacada={sacada}
                          figuras={figuras}
                          bolitas={bolitas}
                        />
                      ))
                  )}
                </div>
              ) : (
                <>
                  <div className=" flex flex-grow flex-row">
                    <SwitchTransition mode="out-in">
                      <CSSTransition
                        key={bolita}
                        addEndListener={(node, done) =>
                          node.addEventListener("transitionend", done, false)
                        }
                        classNames="fade"
                      >
                        {bolita > 0 && bolita < 16 ? (
                          <div>
                            <div className="p-4 pt-2">
                              <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-blue-600 text-sm font-mono font-semibold">
                                B
                              </span>
                              <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-blue-600 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                                {bolita}
                              </div>
                            </div>
                          </div>
                        ) : bolita > 15 && bolita < 31 ? (
                          <div>
                            <div className="p-4 pt-2">
                              <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-amber-300 text-sm font-mono font-semibold">
                                I
                              </span>
                              <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-yellow-400 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                                {bolita}
                              </div>
                            </div>
                          </div>
                        ) : bolita > 30 && bolita < 46 ? (
                          <div>
                            <div className="p-4 pt-2">
                              <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-orange-500 text-sm font-mono font-semibold">
                                N
                              </span>
                              <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-orange-500 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                                {bolita}
                              </div>
                            </div>
                          </div>
                        ) : bolita > 45 && bolita < 61 ? (
                          <div>
                            <div className="p-4 pt-2">
                              <span className="ml-[20px] mt-[-6px] absolute rounded-lg border-1 pl-2 pr-2 bg-green-600 text-sm font-mono font-semibold">
                                G
                              </span>
                              <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-green-600 bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                                {bolita}
                              </div>
                            </div>
                          </div>
                        ) : bolita > 60 && bolita < 76 ? (
                          <div>
                            <div className="p-4 pt-2">
                              <span className="ml-[20px] mt-[-6px] absolute text-white rounded-lg border-1 pl-2 pr-2 bg-black text-sm font-mono font-semibold">
                                O
                              </span>
                              <div className=" text-center pt-[10px] text-3xl p-2 text-teal-200 font-bold rounded-full border-4 border-black bg-gradient-to-tr from-red-500 to-red-700 w-16 h-16">
                                {bolita}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </CSSTransition>
                    </SwitchTransition>

                    {sacada.slice(0, 6).map((val, key) => {
                      return (
                        <>
                          {key == 0 ? (
                            <></>
                          ) : sacada[key] > 0 && sacada[key] < 16 ? (
                            <div>
                              <div className="p-1 pt-4">
                                <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-blue-600 text-xs text-white font-mono font-semibold">
                                  B
                                </span>
                                <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-blue-600  bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                                  {sacada[key]}
                                </div>
                              </div>
                            </div>
                          ) : sacada[key] > 15 && sacada[key] < 31 ? (
                            <div>
                              <div className="p-1 pt-4">
                                <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-amber-300 text-xs font-mono font-semibold">
                                  I
                                </span>
                                <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-yellow-400 bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                                  {sacada[key]}
                                </div>
                              </div>
                            </div>
                          ) : sacada[key] > 30 && sacada[key] < 46 ? (
                            <div>
                              <div className="p-1 pt-4">
                                <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-orange-500 text-xs font-mono font-semibold">
                                  N
                                </span>
                                <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-orange-500 bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                                  {sacada[key]}
                                </div>
                              </div>
                            </div>
                          ) : sacada[key] > 45 && sacada[key] < 61 ? (
                            <div>
                              <div className="p-1 pt-4">
                                <span className="ml-[12px] mt-[-5px] absolute rounded-lg border-1 pl-2 pr-2 bg-green-600 text-xs font-mono font-semibold">
                                  G
                                </span>
                                <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-green-600 bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                                  {sacada[key]}
                                </div>
                              </div>
                            </div>
                          ) : sacada[key] > 60 && sacada[key] < 76 ? (
                            <div>
                              <div className="p-1 pt-4">
                                <span className="ml-[12px] mt-[-5px] text-white absolute rounded-lg border-1 pl-2 pr-2 bg-black text-xs font-mono font-semibold">
                                  O
                                </span>
                                <div className=" text-center text-xl p-2 text-teal-200 font-bold rounded-full border-2 border-black bg-gradient-to-tr from-blue-600 to-blue-950 w-12 h-12">
                                  {sacada[key]}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div className="orden">
                    <Cartonfull sacada={sacada} />
                    <div>
                      <div class=" flex">
                        <div className="relative m-2 mt-10 bg-yellow-500 text-lg text-blue-800 font-mono pl-[50px] font-bold h-[50px] w-[90%] rounded-lg border-2">
                          <span className=" absolute m-0 mt-6 text-sm text-cyan-700">
                            Premio
                          </span>
                          <span className=" flex flex-row pl-2 pr-2 absolute m-0 mt-6 ml-[55px] text-cyan-300 text-sm font-bold bg-slate-800 border-slate-500 rounded-lg">
                            <span>{premio}</span>
                            <span className="ml-1">Bs</span>
                          </span>
                          {sala}
                        </div>
                        <img
                          src={obtenerimg()}
                          height="50px"
                          width="50px"
                          className="m-2 mt-10 rounded-l-lg border-2 absolute h-[50px]"
                        />
                      </div>
                      <div class="flex flex-row">
                        <img
                          src={figuraurl}
                          className="m-2 mt-5 rounded-lg border-2 absolute h-[50px]"
                        />
                        <span className="pl-2 pr-2 absolute m-0 ml-[70px] mt-[35px] text-cyan-300 text-sm font-bold bg-slate-800 border-slate-500 rounded-lg">
                          {namefigura}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {activar == true ? (
              <Confetti
                numberOfPieces={300}
                gravity={0.1}
                width={width}
                height={height}
              />
            ) : (
              <div></div>
            )}
          </div>
        </>
      ) : (
        <div></div>
      )}

      <ToastContainer theme="dark" />
      {/*MODAL*/}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <img
                    className="mt-4 mb-4"
                    src={trofeow}
                    width="60px"
                    height="60px"
                  />
                  <span className="mt-6 pl-2">GANADORES</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 text-center">
                        {ganadors.map((val, key) => {
                          return (
                            <>
                              <div className="flex flex-grow place-content-between items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                <img
                                  class="w-10 h-10 rounded-full"
                                  src={obtenernombre(ganadors[key], "foto")}
                                  alt="Ganador"
                                />
                                <div class="ps-3">
                                  <div class="text-base font-semibold">
                                    {obtenernombre(ganadors[key], "nombre")}
                                  </div>
                                </div>
                                <div className="ml-10 flex flex-col">
                                  <span className=" w-24 text-xs bg-red-800 text-white rounded-t-lg">
                                    Premio
                                  </span>
                                  <span className=" w-24 font-bold bg-cyan-700 p-1 rounded-b-lg text-white">
                                    {premion} Bs
                                  </span>
                                </div>
                              </div>
                              <hr className="mt-2 p-2" />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  {cierre == true ? (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                        }}
                        data-modal-hide="popup-modal"
                        class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 "
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        &nbsp;Cerrar
                      </button>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Admingame;
