import React, { useState, useEffect } from "react";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import logo from "../img/logo.png";
import imgcarton from "../img/tabla.png";
import useSound from "use-sound";
import { Button, Drawer, Radio, Space } from "antd";
import { Select } from "antd";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//IMPORTAR SONIDOS
import popoff from "../sound/pop-up-off.mp3";
import popon from "../sound/pop-up-on.mp3";
import like from "../sound/rising-pops.mp3";
import bite from "../sound/bite.mp3";
import menuopen from "../sound/menu-open.mp3";
import bienvenido from "../sound/clases/bienvenido.mp3";

import { db } from "../firebaseConfig/db";
import { collection } from "firebase/firestore";
import {
  useCollectionData,
  useCollection,
  useFirestore,
  useUpdateDoc,
} from "react-firebase-hooks/firestore";
import {
  doc,
  setDoc,
  addDoc,
  updateDoc,
  getDocs,
  deleteDoc,
} from "@firebase/firestore";

import Confetti from "react-confetti";
import Carton from "../componentes/carton";
import Mostrarcarton from "../componentes/mostrarcarton";
var todoslosc = [];

const Adminis = () => {
  const { width, height } = "100%";
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [activar, SetActivar] = useState(false);
  const [placement, setPlacement] = useState("bottom");

  const [favoritos, setFavoritos] = useState([]);
  const [totalcartones, setTotalcartones] = useState([2]);
  const [miscartones, setMiscartones] = useState({});
  const [cartonespendientes, setCartonespendientes] = useState([]);
  const [cartonselect, setCartonselect] = useState([]);

  const [cartonesusers, setCartonesusers] = useState([]);
  const [cartonestotales, setCartonestotales] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cartos, setCartos] = useState([]);
  const [carton, setCarton] = useState([]);
  const [numcarton, setNumcarton] = useState([]);
  const [emailactual, setEmailactual] = useState([]);
  const [totales, setTotales] = useState([]);
  const [precio, setPrecio] = useState(0);
  const [totalc, setTotalC] = useState(0);
  const [totalr, setTotalR] = useState(0);
  const [totalu, setTotalU] = useState(0);

  const [eliminac, setEliminac] = useState(false);

  //SONIDOS
  const [activar1] = useSound(popon);
  const [desactivar] = useSound(popoff);
  const [megusta] = useSound(like);
  const [comenta] = useSound(bite);
  const [principal] = useSound(menuopen);

  const msjsave = (mensajesave, tipodemensaje) => {
    if (tipodemensaje == "save") {
      toast.success(mensajesave, {});
    }
    if (tipodemensaje == "error") {
      toast.error(mensajesave, {});
    }
    if (tipodemensaje == "warning") {
      toast.warning(mensajesave, {});
    }
    if (tipodemensaje == "info") {
      toast.info(mensajesave, {});
    }
  };

  const mensaje = (value, tipo) => {
    msjsave(value, tipo);
  };

  // GENERAR CARTONES

  //

  const auth = useAuth();
  const { displayName } = auth.user;
  const { email } = auth.user;
  var primerNombre = "";
  if (displayName == undefined) {
  } else {
    let nombre = displayName;

    for (let i = 0; i < nombre.length; i++) {
      if (nombre[i] === " ") {
        i = nombre.length;
      } else {
        primerNombre += nombre[i];
      }
    }
  }
  const photoURL = auth.user.photoURL;
  //console.log(auth.user.photoURL);

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  const handleLogout = () => {
    auth.logout();
  };

  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState([]);

  const getusers = async () => {
    const protemploCollection = collection(db, "cartones");
    const data = await getDocs(protemploCollection);
    //console.log(data.docs);
    setDocuments(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const todosloscartones = () => {
    todoslosc = [];
    for (let i = 0; i < documents.length; i++) {
      //console.log(documents[i].id);
      const collectionRef = collection(
        db,
        `cartones/${documents[i].id}/miscartones`
      );
      getDocs(collectionRef).then((querySnapshot) => {
        const arraydoc = [];
        querySnapshot.forEach((doc) => {
          arraydoc.push({ id: doc.id });
        });

        todoslosc.push({ id: documents[i].id, cartones: arraydoc });
      });
    }
    //console.log(todoslosc);
    setCartonesusers(todoslosc);
  };

  const vermiscartones = (correo) => {
    setCartos([]);
    //console.log(cartonestotales);
    for (let i = 0; i < cartonesusers.length; i++) {
      if (cartonesusers[i].id == correo) {
        for (let a = 0; a < cartonesusers[i].cartones.length; a++) {
          //console.log(cartonesusers[i].cartones[a].id);

          const collectionRef = collection(
            db,
            `cartones/${correo}/miscartones`
          );
          getDocs(collectionRef).then((querySnapshot) => {
            const documentsArray = [];
            querySnapshot.forEach((doc) => {
              documentsArray.push({ id: doc.id, data: doc.data() });
            });
            setCartos(documentsArray);
            //console.log(documentsArray);
          });
        }
      }
    }
  };

  const handleUpdate = (valoremail, valordoc) => {
    //console.log(`valoremail: ${valoremail}`);
    //console.log(`valordoc: ${valordoc}`);
    const miscartoneDoc = doc(
      db,
      `cartones/${valoremail}/miscartones/${valordoc}`
    );

    //console.log(`miscartoneDoc: ${miscartoneDoc.path}`);

    updateDoc(miscartoneDoc, {
      estatus: "comprado",
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });

    setCarton([]);
    vermiscartones(valoremail);
  };

  const handleUpdate2 = (valoremail, valordoc) => {
    //console.log(`valoremail: ${valoremail}`);
    //console.log(`valordoc: ${valordoc}`);
    const miscartoneDoc = doc(
      db,
      `cartones/${valoremail}/miscartones/${valordoc}`
    );

    //console.log(`miscartoneDoc: ${miscartoneDoc.path}`);

    updateDoc(miscartoneDoc, {
      estatus: "regalo",
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });

    setCarton([]);
    vermiscartones(valoremail);
  };

  const calculartotales = () => {
    todoslosc = [];
    for (let i = 0; i < documents.length; i++) {
      //console.log(documents[i].id);
      const collectionRef = collection(
        db,
        `cartones/${documents[i].id}/miscartones`
      );
      getDocs(collectionRef).then((querySnapshot) => {
        const arraydoc = [];
        querySnapshot.forEach((doc) => {
          arraydoc.push({ id: doc.id, data: doc.data() });
        });

        todoslosc.push({
          id: documents[i].id,
          nombres: documents[i].nombre,
          cartones: arraydoc,
        });
      });
    }
    //console.log(todoslosc);
    setCartonestotales(todoslosc);
    datos();
    msjsave("Totales Calculados", "save");
  };

  const datos = async () => {
    const protemploCollection = collection(db, "precio");
    const data = await getDocs(protemploCollection);
    //console.log(data.docs);
    setTotales(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const guardartotales = () => {
    var pendientes = 0;
    var regalos = 0;
    var comprado = 0;
    var usuariost = 0;
    var jugadores = [];

    for (let i = 0; i < cartonestotales.length; i++) {
      var comprobarcomprada = false;
      var nombres = "";
      console.log(todoslosc[i].nombres);
      for (let e = 0; e < todoslosc[i].cartones.length; e++) {
        if (todoslosc[i].cartones[e].data.estatus === "pendiente") {
          pendientes = pendientes + 1;
        }
        if (todoslosc[i].cartones[e].data.estatus === "comprado") {
          comprado = comprado + 1;
          comprobarcomprada = true;
          nombres = todoslosc[i].nombres;
        }
        if (todoslosc[i].cartones[e].data.estatus === "regalo") {
          regalos = regalos + 1;
          comprobarcomprada = true;
          nombres = todoslosc[i].nombres;
        }
      }
      if (nombres !== "") {
        jugadores.push(nombres);
      }
      if (comprobarcomprada === true) {
        usuariost = usuariost + 1;
      }
    }
    //console.log(pendientes, regalos, comprado, usuariost);
    console.log(jugadores);
    const miscartoneDoc = doc(db, `precio/totales`);

    updateDoc(miscartoneDoc, {
      comprados: comprado,
      regalos: regalos,
      pendientes: pendientes,
      usuarios: usuariost,
      jugadores: jugadores,
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });

    msjsave("Totales Guardados con exito", "save");
    calcularpremios();
  };

  const calcularpremios = () => {
    var premiomaracaibo = 0;
    var premiocaracas = 0;
    var premiomerida = 0;
    var premiosaltoangel = 0;
    var totalmonto = parseFloat(totalc) * parseFloat(precio);
    premiomaracaibo = parseFloat(totalmonto) * 0.1;
    premiocaracas = parseFloat(totalmonto) * 0.2;
    premiomerida = parseFloat(totalmonto) * 0.1;
    premiosaltoangel = parseFloat(totalmonto) * 0.4;

    //console.log(totalmonto.toFixed(2));

    const miscartoneDoc = doc(db, `juegos/maracaibo`);

    updateDoc(miscartoneDoc, {
      premio: premiomaracaibo.toFixed(2),
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });

    const miscartoneDoc2 = doc(db, `juegos/merida`);

    updateDoc(miscartoneDoc2, {
      premio: premiomerida.toFixed(2),
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });

    const miscartoneDoc3 = doc(db, `juegos/caracas`);

    updateDoc(miscartoneDoc3, {
      premio: premiocaracas.toFixed(2),
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });

    const miscartoneDoc4 = doc(db, `juegos/saltoangel`);

    updateDoc(miscartoneDoc4, {
      premio: premiosaltoangel.toFixed(2),
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });
  };

  const reiniciar = () => {
    //console.log(cartonestotales);
    for (let i = 0; i < cartonestotales.length; i++) {
      var datoemail = cartonestotales[i].id;
      for (let e = 0; e < cartonestotales[i].cartones.length; e++) {
        var carton = cartonestotales[i].cartones[e].id;
        //console.log(cartonestotales[i].cartones[e].id);
        var miscartoneDoc = doc(
          db,
          `cartones/${datoemail}/miscartones/${carton}`
        );

        updateDoc(miscartoneDoc, {
          estatus: "pendiente",
        })
          .then(() => {
            //console.log("Campo actualizado correctamente");
          })
          .catch((error) => {
            console.error("Error al actualizar campo:", error);
          });
      }
    }
    const miscartoneDoc5 = doc(db, `juegos/maracaibo`);

    updateDoc(miscartoneDoc5, {
      estatus: "En Espera",
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });

    const miscartoneDoc2 = doc(db, `juegos/merida`);

    updateDoc(miscartoneDoc2, {
      estatus: "En Espera",
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });

    const miscartoneDoc3 = doc(db, `juegos/caracas`);

    updateDoc(miscartoneDoc3, {
      estatus: "En Espera",
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });

    const miscartoneDoc4 = doc(db, `juegos/saltoangel`);

    updateDoc(miscartoneDoc4, {
      estatus: "En Espera",
    })
      .then(() => {
        //console.log("Campo actualizado correctamente");
      })
      .catch((error) => {
        console.error("Error al actualizar campo:", error);
      });
    datos();
  };

  const eliminarcarton = async (id, document) => {
    try {
      const docRef = doc(db, `cartones/${id}/miscartones`, document);
      await deleteDoc(docRef);
      //console.log("Documento eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el documento:", error);
    }
  };

  const eliminarcartones = () => {
    //console.log(cartonesusers);

    for (let i = 0; i < cartonesusers.length; i++) {
      //console.log(cartonesusers[i].id);
      for (let a = 0; a < cartonesusers[i].cartones.length; a++) {
        //console.log(cartonesusers[i].cartones[a].id);
        eliminarcarton(cartonesusers[i].id, cartonesusers[i].cartones[a].id);
      }
    }

    todosloscartones();
  };

  useEffect(() => {
    //console.log(cartonesusers);
  }, [cartonesusers]);

  useEffect(() => {
    getusers();
    datos();
  }, []);

  useEffect(() => {
    //console.log(documents);
    todosloscartones();
  }, [documents]);

  useEffect(() => {
    console.log(cartonestotales);
  }, [cartonestotales]);

  useEffect(() => {
    if (totales.length > 0) {
      setTotalC(totales[0].comprados);
      setTotalR(totales[0].regalos);
      setTotalU(totales[0].usuarios);
      setPrecio(totales[0].precio);
    }
  }, [totales]);

  useEffect(() => {
    //console.log(totalc);
  }, [totalc]);

  useEffect(() => {
    console.log(documents);
  }, [documents]);

  return (
    <>
      <div className="container w-screen">
        <div className=" p-2 h-28 w-full flex place-content-between items-center">
          <div className="m-0 flex flex-wrap flex-col place-content-around">
            <Link to="/">
              <img
                className="pt-2 mt-[-10px]"
                src={logo}
                width="300px"
                height="100%"
                alt="logo"
              />
            </Link>
            <p className="p-1"></p>
          </div>

          {displayName == undefined ? (
            <button
              type="button"
              onClick={(e) => handleGoogle(e)}
              class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
            >
              <svg
                class="w-4 h-4 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              Entrar
            </button>
          ) : (
            <div className="flex">
              <div className="p-2">
                <div className="flex flex-col items-end gap-4 ">
                  <img
                    onClick={handleLogout}
                    className="w-10 h-10  rounded-full border-2 border-blue-500"
                    src={`${photoURL}`}
                    alt=""
                  />
                  <div className="font-medium text-white mt-[-10px]">
                    <div className="text-white text-right text-sm">
                      {displayName && <> {primerNombre} </>}
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer theme="dark" />
      <br />

      {email == "enmarycarrasco@gmail.com" ? (
        <>
          <div class=" flex">
            <div className="m-2 mt-10 bg-yellow-400 pt-2 text-xl text-blue-800 font-mono pl-28 font-bold absolute h-[50px] w-[96%] rounded-lg border-2">
              GESTIONAR CARTONES
            </div>
            <img className="relative ml-4" src={imgcarton} width="100px" />
          </div>
          <div class=" flex">
            <div className=" bg-white w-full">
              <div className="m-4 text-blue-700 font-bold">
                Usuarios y cartones
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" class="px-6 py-3">
                          Nombre
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Cartones
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Status
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.map((doc, index) => (
                        <>
                          <tr
                            key={doc.id}
                            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                          >
                            <th
                              scope="row"
                              class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <img
                                class="w-10 h-10 rounded-full"
                                src={doc.photo}
                                alt="Jese image"
                              />
                              <div class="ps-3">
                                <div class="text-base font-semibold">
                                  {doc.nombre}
                                </div>
                                <div class="font-normal text-gray-500">
                                  {doc.email}
                                </div>
                              </div>
                            </th>
                            <td class="px-6 py-4">
                              <button
                                onClick={() => {
                                  setCarton([]);
                                  vermiscartones(doc.id);
                                  setEmailactual(doc.id);

                                  setShowModal(true);
                                }}
                                type="button"
                                class="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
                              >
                                <svg
                                  class="w-6 h-6text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                &nbsp; Ver Cartones
                              </button>
                            </td>
                            <td class="px-6 py-4">
                              <div class="flex items-center">
                                <div class="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>{" "}
                                {doc.id}
                              </div>
                            </td>
                            <td class="px-6 py-4">
                              <a
                                href="#"
                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                              >
                                Edit user
                              </a>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <br />

          <button
            onClick={() => {
              calculartotales();
            }}
            type="button"
            class="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
          >
            <svg
              class="w-6 h-6text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                clip-rule="evenodd"
              />
            </svg>
            &nbsp; Calcular Totales
          </button>

          <button
            onClick={() => {
              guardartotales();
            }}
            type="button"
            class="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
          >
            <svg
              class="w-6 h-6text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                clip-rule="evenodd"
              />
            </svg>
            &nbsp; Guardar Totales
          </button>

          <button
            onClick={() => {
              reiniciar();
            }}
            type="button"
            class="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
          >
            <svg
              class="w-6 h-6text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                clip-rule="evenodd"
              />
            </svg>
            &nbsp; Reiniciar
          </button>

          <button
            onClick={() => {
              setEliminac(true);
            }}
            type="button"
            class="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
          >
            <svg
              class="w-6 h-6text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                clip-rule="evenodd"
              />
            </svg>
            &nbsp; Eliminar Cartones
          </button>

          <div>
            {activar == true ? (
              <Confetti
                numberOfPieces={300}
                gravity={0.1}
                width={width}
                height={height}
              />
            ) : (
              <div></div>
            )}

            {open == false || open2 == false ? (
              <div></div>
            ) : (
              <div className="footer bg-black flex flex-wrap items-center p-0 justify-end text-white">
                <div className="">
                  <button
                    onClick={() => {
                      principal();
                      SetActivar(false);
                    }}
                    type="button"
                    class=" w-full  text-blue-700  hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 "
                  >
                    <svg
                      class=" relative w-[48px] h-[48px] text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span class=" absolute inline-flex items-center justify-center w-6 h-6 ml-8 mt-[-26px] text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                      0
                    </span>
                  </button>
                </div>
                <div className="">
                  <button
                    type="button"
                    onClick={() => {
                      SetActivar(true);
                      comenta();
                    }}
                    class="relative  w-full  font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2"
                  >
                    <svg
                      class="w-[48px] h-[48px] text-white dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 4a1 1 0 0 1 1-1h1.5a1 1 0 0 1 .979.796L7.939 6H19a1 1 0 0 1 .979 1.204l-1.25 6a1 1 0 0 1-.979.796H9.605l.208 1H17a3 3 0 1 1-2.83 2h-2.34a3 3 0 1 1-4.009-1.76L5.686 5H5a1 1 0 0 1-1-1Z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <span class=" absolute inline-flex items-center justify-center w-6 h-6 ml-8 mt-[-26px] text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                      0
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div></div>
      )}

      {/*MODAL*/}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="p-4 md:p-5 text-center">
                    <div>
                      <div className="p-2 md:p-2 text-center">
                        <h3 className=" text-lg font-normal text-gray-500 dark:text-gray-400">
                          Confirmar Cartones
                        </h3>
                      </div>
                      <div class="flex items-start gap-2.5">
                        {cartos.map((doc, index) => (
                          <>
                            {doc.data.estatus == "pendiente" ? (
                              <div key={doc.id}>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setDocument(doc.id);
                                    setCarton(doc.data);

                                    setNumcarton(index + 1);
                                  }}
                                  class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                  <svg
                                    class="w-6 h-6 text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm2 8v-2h7v2H4Zm0 2v2h7v-2H4Zm9 2h7v-2h-7v2Zm7-4v-2h-7v2h7Z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  &nbsp;#{index + 1}
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </div>

                      <br />
                      <div className="flex">
                        {carton == "" ? (
                          <div></div>
                        ) : (
                          <>
                            <Mostrarcarton
                              email={emailactual}
                              numeros={carton}
                            />
                            <div className="flex flex-col">
                              <div className="p-4 text-blue-900 font-bold text-xl">
                                Carton #{numcarton}
                              </div>
                              <div className="pl-4 flex flex-col">
                                <button
                                  onClick={() => {
                                    handleUpdate(emailactual, document);
                                  }}
                                  type="button"
                                  class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                >
                                  Aceptar
                                </button>
                                <button
                                  onClick={() => {
                                    handleUpdate2(emailactual, document);
                                  }}
                                  type="button"
                                  class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                >
                                  Regalo
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <hr className="mt-2 p-2" />
                    <button
                      onClick={() => {
                        setShowModal(false);
                      }}
                      data-modal-hide="popup-modal"
                      type="button"
                      className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {eliminac ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-white">
                {/*body*/}
                <div className="h-20 bg-blue-800 rounded-t-lg place-content-center flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <span className="mt-6 pl-2">Eliminar Cartones</span>
                </div>
                <div className="relative p-1 flex-auto">
                  <div className="p-1 md:p-1 text-center">
                    <div>
                      <div className="p-2 md:p-2 font-bold text-center">
                        Desea Eliminar todos los cartones?
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-18 bg-blue-800 rounded-b-lg place-content-end flex flex-grow text-cyan-300 text-2xl font-semibold font-mono">
                  <button
                    type="button"
                    onClick={() => {
                      setEliminac(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    &nbsp;Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      eliminarcartones();
                      setEliminac(false);
                    }}
                    data-modal-hide="popup-modal"
                    class="m-2 text-white bg-[#a51b1b] hover:bg-[#d12121]/90 focus:ring-4 focus:outline-none focus:ring-[#d12121]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                  >
                    <svg
                      class="w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                    </svg>
                    &nbsp;Eliminar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Adminis;
