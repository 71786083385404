import React, { useState, useEffect } from "react";

import { db } from "../firebaseConfig/db";

import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc } from "@firebase/firestore";
import trofeow from "../img/trofeow.png";

const Cartonfull = ({ sacada }) => {
  const marcarresultados = () => {
    for (let i = 0; i < sacada.length; i++) {
      revisarcarton(sacada[i]);
    }
  };

  const revisarcarton = (bolitanueva) => {
    if (bolitanueva > 0 && bolitanueva < 16) {
      chequearb(bolitanueva);
    }
    if (bolitanueva > 15 && bolitanueva < 31) {
      chequeari(bolitanueva);
    }
    if (bolitanueva > 30 && bolitanueva < 46) {
      chequearn(bolitanueva);
    }
    if (bolitanueva > 45 && bolitanueva < 61) {
      chequearg(bolitanueva);
    }
    if (bolitanueva > 60 && bolitanueva < 76) {
      chequearo(bolitanueva);
    }
  };

  const chequearb = (bolita) => {
    for (let i = 1; i <= 15; i++) {
      if (bolita == i) {
        document.getElementById(`b${i}`).className = "marcador3";
      }
    }
  };

  const chequeari = (bolita) => {
    for (let i = 16; i <= 30; i++) {
      if (bolita == i) {
        document.getElementById(`i${i}`).className = "marcador3";
      }
    }
  };

  const chequearn = (bolita) => {
    for (let i = 31; i <= 45; i++) {
      if (bolita == i) {
        document.getElementById(`n${i}`).className = "marcador3";
      }
    }
  };

  const chequearg = (bolita) => {
    for (let i = 46; i <= 60; i++) {
      if (bolita == i) {
        document.getElementById(`g${i}`).className = "marcador3";
      }
    }
  };

  const chequearo = (bolita) => {
    for (let i = 61; i <= 75; i++) {
      if (bolita == i) {
        document.getElementById(`o${i}`).className = "marcador3";
      }
    }
  };

  useEffect(() => {
    marcarresultados();
    //console.log(sacada);
  }, [sacada]);

  return (
    <>
      <table id="bingo-table">
        <tr>
          <th>
            <span class="encabezado1">B</span>
          </th>
          <th>
            <span class="encabezado1">I</span>
          </th>
          <th>
            <span class="encabezado1">N</span>
          </th>
          <th>
            <span class="encabezado1">G</span>
          </th>
          <th>
            <span class="encabezado1">O</span>
          </th>
        </tr>
        <tr>
          <td id="b1" class="">
            1
          </td>
          <td id="i16" class="">
            16
          </td>
          <td id="n31" class="">
            31
          </td>
          <td id="g46" class="">
            46
          </td>
          <td id="o61" class="">
            61
          </td>
        </tr>
        <tr>
          <td id="b2" class="">
            2
          </td>
          <td id="i17" class="">
            17
          </td>
          <td id="n32" class="">
            32
          </td>
          <td id="g47" class="">
            47
          </td>
          <td id="o62" class="">
            62
          </td>
        </tr>
        <tr>
          <td id="b3" class="">
            3
          </td>
          <td id="i18" class="">
            18
          </td>
          <td id="n33" class="">
            33
          </td>
          <td id="g48" class="">
            48
          </td>
          <td id="o63" class="">
            63
          </td>
        </tr>
        <tr>
          <td id="b4" class="">
            4
          </td>
          <td id="i19" class="">
            19
          </td>
          <td id="n34" class="">
            34
          </td>
          <td id="g49" class="">
            49
          </td>
          <td id="o64" class="">
            64
          </td>
        </tr>
        <tr>
          <td id="b5" class="">
            5
          </td>
          <td id="i20" class="">
            20
          </td>
          <td id="n35" class="">
            35
          </td>
          <td id="g50" class="">
            50
          </td>
          <td id="o65" class="">
            65
          </td>
        </tr>
        <tr>
          <td id="b6" class="">
            6
          </td>
          <td id="i21" class="">
            21
          </td>
          <td id="n36" class="">
            36
          </td>
          <td id="g51" class="">
            51
          </td>
          <td id="o66" class="">
            66
          </td>
        </tr>
        <tr>
          <td id="b7" class="">
            7
          </td>
          <td id="i22" class="">
            22
          </td>
          <td id="n37" class="">
            37
          </td>
          <td id="g52" class="">
            52
          </td>
          <td id="o67" class="">
            67
          </td>
        </tr>
        <tr>
          <td id="b8" class="">
            8
          </td>
          <td id="i23" class="">
            23
          </td>
          <td id="n38" class="">
            38
          </td>
          <td id="g53" class="">
            53
          </td>
          <td id="o68" class="">
            68
          </td>
        </tr>
        <tr>
          <td id="b9" class="">
            9
          </td>
          <td id="i24" class="">
            24
          </td>
          <td id="n39" class="">
            39
          </td>
          <td id="g54" class="">
            54
          </td>
          <td id="o69" class="">
            69
          </td>
        </tr>
        <tr>
          <td id="b10" class="">
            10
          </td>
          <td id="i25" class="">
            25
          </td>
          <td id="n40" class="">
            40
          </td>
          <td id="g55" class="">
            55
          </td>
          <td id="o70" class="">
            70
          </td>
        </tr>
        <tr>
          <td id="b11" class="">
            11
          </td>
          <td id="i26" class="">
            26
          </td>
          <td id="n41" class="">
            41
          </td>
          <td id="g56" class="">
            56
          </td>
          <td id="o71" class="">
            71
          </td>
        </tr>
        <tr>
          <td id="b12" class="">
            12
          </td>
          <td id="i27" class="">
            27
          </td>
          <td id="n42" class="">
            42
          </td>
          <td id="g57" class="">
            57
          </td>
          <td id="o72" class="">
            72
          </td>
        </tr>
        <tr>
          <td id="b13" class="">
            13
          </td>
          <td id="i28" class="">
            28
          </td>
          <td id="n43" class="">
            43
          </td>
          <td id="g58" class="">
            58
          </td>
          <td id="o73" class="">
            73
          </td>
        </tr>
        <tr>
          <td id="b14" class="">
            14
          </td>
          <td id="i29" class="">
            29
          </td>
          <td id="n44" class="">
            44
          </td>
          <td id="g59" class="">
            59
          </td>
          <td id="o74" class="">
            74
          </td>
        </tr>
        <tr>
          <td id="b15" class="">
            15
          </td>
          <td id="i30" class="">
            30
          </td>
          <td id="n45" class="">
            45
          </td>
          <td id="g60" class="">
            60
          </td>
          <td id="o75" class="">
            75
          </td>
        </tr>
      </table>
    </>
  );
};

export default Cartonfull;
