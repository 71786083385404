import { initializeApp } from "firebase/app";

import { getFirestore } from "@firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE,
  authDomain: "bingo-6ba64.firebaseapp.com",
  projectId: "bingo-6ba64",
  storageBucket: "bingo-6ba64.appspot.com",
  messagingSenderId: "616877670907",
  appId: "1:616877670907:web:be8e722badcf23094b0b5e",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
